<script>
export default {
    AvatarUrl:"https://ucarecdn.com/a7e92f47-57ba-4713-83f3-9b40320e9c30/avatar.png",
    Name:"Yuntao Xie",
    ShortBio:" A cutting-edge technology explorer",
    About:`
- 👋 Hi, I’m Yuntao Xie, nice to meet you!<br>
- 👀 I’m interested in Machine Learning, Medical Signal Processing, Fraud Detection, Software Security, Natural Language Processing.<br>
- 🌱 I’m currently learning Wireless Senor Network(focus on improving the LEACH protocol)<br>
- 😃 I can speak Chinese(Native), English(Fluent) and Cantonese(Native)<br>
`,
    DisplayProjectCard:true,
    ProjectInfo:[
        {
            id:0,
            ProjectImg: ["https://ieeexplore.ieee.org/mediastore_new/IEEE/content/media/19/9259274/9406046/wang3abc-3073707-large.gif",
            "https://ieeexplore.ieee.org/mediastore_new/IEEE/content/media/19/9259274/9406046/wang1-3073707-large.gif",
            "https://ieeexplore.ieee.org/mediastore_new/IEEE/content/media/19/9259274/9406046/wang2abc-3073707-large.gif",
            "https://ieeexplore.ieee.org/mediastore_new/IEEE/content/media/19/9259274/9406046/wang5-3073707-large.gif",
            "https://ieeexplore.ieee.org/mediastore_new/IEEE/content/media/19/9259274/9406046/wang6-3073707-large.gif",
            "https://ieeexplore.ieee.org/mediastore_new/IEEE/content/media/19/9259274/9406046/wang10-3073707-large.gif"
            ],
            ProjectDes: "Deform-CNN",
            ProjectDate: "Deformable convolutional networks for ECG diagnosis",
            ProjectLink: "https://github.com/HeartbeatAI/Deform-CNN",
        },
        {
            id:1,
            ProjectImg: ["https://ucarecdn.com/023ef181-48f8-450c-a9be-eafa07a6c6cd/heartbeataicloud.jpg",],
            ProjectDes: "HeartbeatAI Cloud Platform",
            ProjectDate: "Online out-of-the-box ECG diagnostic platform",
            ProjectLink: "https://hbai.co",
        },
        {
            id:2,
            ProjectImg: ["https://ucarecdn.com/e557f589-f481-4bfe-891d-60c8ffc55dd6/urlshortener.png",],
            ProjectDes: "Url Shorten Worker",
            ProjectDate: "URL Shortener using severless technology, 500+ Stars on Github",
            ProjectLink: "https://github.com/xyTom/Url-Shorten-Worker",
        },
        {
            id:3,
            ProjectImg: ["https://ucarecdn.com/63aeaf5f-8bf1-4562-b638-65bd2a426018/MyBio.jpg",],
            ProjectDes: "MyBio",
            ProjectDate: "Responsive, easy to create personal homepage. Yes, it is this page.",
            ProjectLink: "https://github.com/xyTom/My-Bio",
        },
        {
            id:4,
            ProjectImg: ["https://ucarecdn.com/c64bbb77-7855-430d-9998-764d07357cb4/byj.jpg",
            "https://ucarecdn.com/157f262f-8526-4416-a99c-4de388c648d7/byj2.png",
            "https://ucarecdn.com/debd16c9-f2e4-4e47-82d0-88db8017106a/byj3.png",
            "https://ucarecdn.com/80781fb7-f1a5-40cb-975a-4d58f58365a4/byj4.png",
            "https://ucarecdn.com/d804153d-467a-4f7d-bdcc-3e161337a051/byj5.png"
            ],
            ProjectDes: "The last quiz for the graduates at UESTC",
            ProjectDate: "More than 800 Graduates participated in the quiz",
            ProjectLink: "",
        },
        {
            id:5,
            ProjectImg: ["https://ucarecdn.com/47a7199c-b09a-43aa-b517-0b4a40106195/WhereAboutMap.png",],
            ProjectDes: "WhereAbout",
            ProjectDate: "Dynamic Graduation Orientation Electronic Map",
            ProjectLink: "https://gowhere.azurewebsites.net",
        },
        {
            id:6,
            ProjectImg: ["https://ucarecdn.com/64be2808-ce81-4b74-a5b4-fc95903c6935/spermanno.png",],
            ProjectDes: "Sperm-Anno",
            ProjectDate: "Sperm morphology labelling tool for Machine learning",
            ProjectLink: "",
        },
      ]
}
</script>
